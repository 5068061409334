import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import _ from "lodash";

const TOTAL_TILE_COUNT = 16; // Must be a square number

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score ? data.score : 0;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.isBossRound = data.round == data.bossRound && data.isCorrectAnswer;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
  }

  avatarObject = [];
  friendAvatarObject = [];
  treasure = {};
  tileList = [];
  metalWall = [];
  newMetalWall = [];
  tileCount = 0;
  missClicks = 0;
  isGameStart = false;
  isGameEnd = false;
  isGemCracked = false;
  timerWarned = false;
  stopPlay = false;
  friendEntered = false;
  isgetGem = false;

  create() {
    // change timer for devices
    this.TIMER_VALUE =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? this.difficulty == 3
          ? 7000
          : this.difficulty == 2
          ? 9000
          : 13000
        : this.difficulty == 3
        ? 8000
        : this.difficulty == 2
        ? 10000
        : 14000;

    this.gemScore = Math.floor(Math.random() * (20 - 15 + 1)) + 15;

    if (this.isCorrectAnswer) {
      this._tile_dirt_count = Math.floor(Math.random() * (5 - 3 + 1)) + 3;
      this._tile_stone_count = Math.floor(Math.random() * (5 - 4 + 1)) + 4;
      this._tile_steel_count = Math.floor(Math.random() * (4 - 3 + 1)) + 3;
      this._tile_clock_count = 1;
    } else {
      this._tile_dirt_count = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
      this._tile_stone_count = Math.floor(Math.random() * (8 - 6 + 1)) + 6;
      this._tile_steel_count = Math.floor(Math.random() * (6 - 4 + 1)) + 4;
      this._tile_clock_count = 0;
    }
    // background
    this.add.image(240, 427, "bg").setScale(0.5);
    this.bg = this.add.image(240, 427, "bg").setScale(0.5);
    this.wall = this.add.image(240, 390, "wall").setScale(1.12);
    this.scoreBoard = this.add.image(80, 35, "score").setScale(0.3);
    this.resultBoard = this.add.image(70, 70, "score").setScale(0.25, 0.2);
    this.settingBtn = this.add
      .image(440, 40, "setting")
      .setScale(0.6)
      .setDepth(99);
    this.settingBtn.on("pointerdown", clickSetting, this);
    this.settingBtn.setInteractive({ useHandCursor: true });
    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5);

    this.speechText = this.add
      .text(100, 650, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 24,
        color: "#492a12",
      })
      .setOrigin(0.5)
      .setAlpha(0);

    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5);

    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(99);
    this.feedback.setShadow(3, 3, "#000", 0, true, true);

    // Sounds
    this.digStoneSound = this.sound.add("digStone");
    this.digGemSound = this.sound.add("digGem");
    this.spillStonesSound = this.sound.add("spillStones");
    this.gemSplashSound = this.sound.add("gemSplash");
    this.bombingSound = this.sound.add("bombing");
    this.bombingSound_2 = this.sound.add("bombing_2");
    this.scoreIncreaseSound = this.sound.add("scoreIncrease");
    this.collectGemSound = this.sound.add("collectGem");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.failureSound = this.sound.add("failure");
    this.gemLossSound = this.sound.add("stealGem");
    // this.readySound = this.sound.add("ready");
    this.goSound = this.sound.add("go");
    this.gamdeTimerSound = this.sound.add("timerGame");
    this.gamdeTimerSound.loop = true;
    this.gemBlinkSound = this.sound.add("gemBlink");
    this.gemLostSound = this.sound.add("gemLost");
    this.clearedSound = this.sound.add("cleared");
    this.bombThrowSound = this.sound.add("bombThrow");
    this.freezeSound = this.sound.add("freeze");

    this.mask;
    this.friendMask;

    // animations
    this.anims.create({
      key: "explode",
      frames: this.anims.generateFrameNumbers(
        "explosion",
        [0, 1, 2, 3, 4, 5, 6, 7]
      ),
      frameRate: 10,
    });

    this.anims.create({
      key: "wallBreak",
      frames: this.anims.generateFrameNumbers("breaking", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "dig",
      frames: this.anims.generateFrameNumbers("pickaxe", {
        frames: [1, 2, 3, 2, 1, 0],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "diging",
      frames: this.anims.generateFrameNumbers("boom", {
        frames: [1, 2, 3, 2, 1, 0],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "gemCrack",
      frames: this.anims.generateFrameNumbers("breaking", {
        frames: [0, 1],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "gemBreak",
      frames: this.anims.generateFrameNumbers("breaking", {
        frames: [2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "fill",
      frames: this.anims.generateFrameNumbers("stones", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "bomb_freeze",
      frames: this.anims.generateFrameNumbers("freeze_bomb_sprite", {
        frames: [0, 1, 2, 3, 2, 1, 0],
      }),
      frameRate: 5,
      repeat: -1,
    });

    // pre mining scene ***Starts here***
    this.explosion = this.add
      .sprite(240, 400, "explosion")
      .setScale(0)
      .setDepth(6);
    this.bombPath = new Phaser.Curves.Path(170, 750).splineTo([250, 400]);
    this.bomb = this.add
      .follower(
        this.bombPath,
        170,
        750,
        this.isCorrectAnswer ? "bomb1" : "bomb0"
      )
      .setScale(0.35)
      .setDepth(5);

    this.modelbg = this.add
      .rectangle(240, 390, 408, 100, 0xffffff)
      .setDepth(9)
      .setAlpha(0);

    this.bomb.startFollow({
      positionOnPath: true,
      duration: 200,
      rotateToPath: false,
      verticalAdjust: true,
      onComplete: () => {
        this.tweens.add({
          targets: this.bomb,
          y: 380,
          scaleY: 0.38,
          scalex: 0.33,
          duration: 500,
          ease: "Sine.easeInOut",
          delay: 200,
          yoyo: true,
          onComplete: () => {
            if (this.isCorrectAnswer) {
              this.bombingSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.bombingSound.play();
            } else {
              this.bombingSound_2.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.bombingSound_2.play();
            }

            this.cameras.main.shake(500);
            this.explosion.play("explode");
            this.tweens.add({
              targets: this.explosion,
              scaleX: 2,
              scaleY: 2,
              duration: 500,
              ease: "Circular.In",
              onComplete: () => {
                this.metalWall.forEach((tile) => {
                  tile.destroy();
                });
                this.bomb.destroy();
                this.metalWall = [];
                this.tweens.add({
                  targets: this.explosion,
                  alpha: 0,
                  duration: 1000,
                  ease: "Sine.easeInOut",
                  delay: 100,
                  onComplete: () => {
                    this.feedback.setText(i18n.t("miniGames.general.ready"));
                    this.feedback.setTint(0xffffff);
                    this.tweens.add({
                      targets: this.feedback,
                      scaleX: 1.5,
                      scaleY: 1.5,
                      duration: 200,
                      ease: "Sine.easeInOut",
                      // onStart: ()=>{
                      //   this.readySound.setVolume(
                      //     store.state.settings.data.audio.sfx *
                      //       store.state.settings.data.audio.master
                      //   );
                      //   this.readySound.play();
                      // },
                      onComplete: () => {
                        this.tweens.add({
                          targets: this.feedback,
                          scaleX: 0,
                          scaleY: 0,
                          duration: 100,
                          ease: "Sine.easeInOut",
                          delay: 1000,
                          onComplete: () => {
                            this.feedback.setText(
                              i18n.t("miniGames.general.go")
                            );
                            this.feedback.setTint(0xffffff);
                            this.tweens.add({
                              targets: this.feedback,
                              scaleX: 1.5,
                              scaleY: 1.5,
                              duration: 200,
                              ease: "Sine.easeInOut",
                              delay: 500,
                              // onStart: ()=>{
                              //   this.readySound.setVolume(
                              //     store.state.settings.data.audio.sfx *
                              //       store.state.settings.data.audio.master
                              //   );
                              //   this.readySound.play();
                              // },
                              onComplete: () => {
                                this.tweens.add({
                                  targets: this.feedback,
                                  scaleX: 0,
                                  scaleY: 0,
                                  duration: 100,
                                  ease: "Sine.easeInOut",
                                  delay: 1000,
                                  onStart: () => {
                                    this.goSound.setVolume(
                                      store.state.settings.data.audio.sfx *
                                        store.state.settings.data.audio.master
                                    );
                                    this.goSound.play();
                                  },
                                  onComplete: () => {
                                    this.isGameStart = true;
                                    // Start Timer
                                    this.timedEvent = this.time.delayedCall(
                                      this.TIMER_VALUE,
                                      onEvent,
                                      [],
                                      this
                                    );
                                  },
                                });
                              },
                            });
                          },
                        });
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
    // pre mining scene ***Ends here***

    // Timer Canvas
    this.clock = this.add.image(90, 140, "clock").setScale(0.5).setDepth(10);
    this.freezeClock = this.add
      .image(90, 140, "freezeClock")
      .setScale(0.3)
      .setDepth(11);
    this.freezeClock.visible = false;
    this.bubbleBar = this.add
      .image(250, 140, "freezeTimeBar")
      .setScale(0.35, 0.3)
      .setAlpha(0.75)
      .setDepth(10);
    this.bubbleBar.visible = false;
    this.timerBox = this.add
      .graphics()
      .fillStyle(0x875f41, 1)
      .fillRect(100, 130, 310, 20);
    this.timerBar = this.add
      .graphics()
      .fillStyle(0x23de46, 1)
      .fillRect(105, 135, 300, 10);
    this.timerBarRe = this.add.graphics().fillStyle(0x875f41, 1);

    // Treasure
    this.treasurePath = new Phaser.Curves.Path(240, 390).splineTo([
      170, 300, 100, 360, 100, 730,
    ]);

    this.treasure = this.add
      .follower(
        this.treasurePath,
        240,
        390,
        this.isBossRound ? "bossGem" : "gem"
      )
      .setScale(this.isBossRound ? 0.4 : 0.35);
    this.treasure.setInteractive({
      cursor: "pointer",
    });

    this.treasure.anim = this.add
      .sprite(240, 390, "breaking")
      .setFrame(0)
      .setScale(0.9)
      .setVisible(false);
    this.treasure.mask = this.treasure.anim.createBitmapMask();

    if (this.isBossRound) {
      this.treasureBlink = this.add.image(240, 390, "blink").setScale(0);
      this.gemTween = this.tweens.add({
        onStart: () => {
          this.gemBlinkSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.gemBlinkSound.play();
        },
        onRepeat: () => {
          this.gemBlinkSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.gemBlinkSound.play();
        },
        targets: this.treasureBlink,
        scaleX: 0.3,
        scaleY: 0.3,
        duration: 200,
        yoyo: true,
        ease: "Sine.easeInOut",
        repeat: -1,
        repeatDelay: 3000,
      });
    }

    // tiles creation logic
    let map = randomMap(
      TOTAL_TILE_COUNT,
      this._tile_dirt_count,
      this._tile_stone_count,
      this._tile_steel_count,
      this._tile_clock_count
    );

    let rawCount = Math.sqrt(TOTAL_TILE_COUNT);
    let colCount = Math.sqrt(TOTAL_TILE_COUNT);

    for (let j = 0; j < rawCount; j++) {
      for (let i = 0; i < colCount; i++) {
        if (map[this.tileCount] > 0) {
          let tileButton = {};
          if (map[this.tileCount] == 1) {
            tileButton = this.add.sprite(
              90 + i * (400 / colCount),
              240 + j * (400 / rawCount),
              "dirt_tile"
            );
          }
          if (map[this.tileCount] == 2) {
            tileButton = this.add.sprite(
              90 + i * (400 / colCount),
              240 + j * (400 / rawCount),
              "stone_tile"
            );
          }
          if (map[this.tileCount] == 3) {
            tileButton = this.add.sprite(
              90 + i * (400 / colCount),
              240 + j * (400 / rawCount),
              "steel_tile"
            );
          }
          tileButton.index = this.tileCount;
          tileButton.name = "tile";
          tileButton.setInteractive({
            cursor: "pointer",
          });
          tileButton.type = map[this.tileCount];
          tileButton.life = map[this.tileCount];
          tileButton.anim = this.add
            .sprite(90 + i * 100, 240 + j * 100, "breaking")
            .setScale(0.25)
            .setFrame(0)
            .setVisible(false);
          tileButton.mask = tileButton.anim.createBitmapMask();

          tileButton.setMask(tileButton.mask);
          this.tileList.push(tileButton);
        }
        if (map[this.tileCount] == -1) {
          let tileButton = {};

          tileButton = this.add.sprite(380, 700, "freeze_bomb_sprite");
          tileButton.setScale(0.5);
          tileButton.visible = false;

          tileButton._x = 90 + i * 100;
          tileButton._y = 240 + j * 100;

          tileButton.index = this.tileCount;
          tileButton.name = "freezeClock";
          tileButton.setInteractive({
            cursor: "pointer",
          });
          this.tileList.push(tileButton);
        }
        this.tileCount++;
      }
    }

    // change 4 to raw count
    for (let j = 0; j < 4; j++) {
      for (let i = 0; i < 4; i++) {
        let tile = {};
        tile = this.add.image(90 + i * 100, 240 + j * 100, "steelWall");
        this.metalWall.push(tile);
      }
    }

    // avatar
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.add.image(100, 720, key).setScale(0.3).setDepth(5)
          );
          this.avatarObject.push(
            this.add
              .image(100, 720, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.3)
              .setAlpha(0.3)
              .setDepth(5)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.add.image(100, 720, key).setScale(0.3).setDepth(5)
          );
        }
      }
    }

    if (
      this.friend &&
      this.isCorrectAnswer &&
      !this.isBossRound &&
      (Math.random() <= 0.2 || (this.forceFriend && this.round == 1)) &&
      this.friend.name
    ) {
      // friendavatar
      for (let key of Object.keys(this.friend.avatar)) {
        if (this.friend.avatar[key]) {
          if (key == "body") {
            this.friendAvatarObject.push(
              this.add.image(580, 720, key).setScale(-0.3, 0.3).setDepth(5)
            );
            this.friendAvatarObject.push(
              this.add
                .image(580, 720, key)
                .setTint("0x" + this.friend.avatar.color)
                .setScale(-0.3, 0.3)
                .setAlpha(0.3)
                .setDepth(5)
            );
          } else if (key != "color") {
            this.friendAvatarObject.push(
              this.add.image(580, 720, key).setScale(-0.3, 0.3).setDepth(5)
            );
          }
        }
      }

      let freezeClockIndex = this.tileList.findIndex((element) => {
        return element.name === "freezeClock";
      });

      this.friendName = this.add
        .text(580, 815, this.friend.name, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#ffffff",
        })
        .setOrigin(0.5);

      this.time.delayedCall(10000, () => {
        if (!this.stopPlay) {
          this.tweens.add({
            targets: this.friendAvatarObject,
            x: 380,
            duration: 1000,
            ease: "Power2",
            repeat: 0,
            onStart: () => {
              this.tweens.add({
                targets: this.friendAvatarObject,
                scaleY: 0.35,
                scaleX: -0.29,
                y: 710,
                duration: 500,
                ease: "Sine.easeInOut",
                repeat: 2,
                yoyo: true,
              });
              this.tweens.add({
                targets: this.friendName,
                x: 380,
                duration: 1000,
                ease: "Power2",
                repeat: 0,
              });
            },
            onComplete: () => {
              if (this.mask) {
                this.mask.destroy();
              }
              this.mask = this.add
                .image(100, 720, "happy")
                .setScale(0.3)
                .setDepth(5);
              this.tileList[freezeClockIndex].visible = true;
              this.tileList[freezeClockIndex].play("bomb_freeze");
              this.tweens.add({
                targets: this.tileList[freezeClockIndex],
                x: this.tileList[freezeClockIndex]._x,
                y: this.tileList[freezeClockIndex]._y,
                scaleX: 1,
                scaleY: 1,
                duration: 300,
                ease: "Sine.easeInOut",
                repeat: 0,
                onStart: () => {
                  this.friendEntered = true;
                  this.bombThrowSound.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.bombThrowSound.play();
                },
              });
            },
          });
        }
      });
    }

    // pickaxe
    this.staticAxe = this.add
      .sprite(this.avatarObject[0].x + 60, this.avatarObject[0].y, "pickaxe")
      .setScale(0.6);

    this.boom = this.add.sprite(0, 0, "boom").setScale(0.9).setFrame(0);

    this.pickaxe = this.add
      .sprite(this.avatarObject[0].x + 60, this.avatarObject[0].y, "pickaxe")
      .setScale(0.6);

    this.input.on(
      "pointerdown",
      function (event, gameObjects) {
        let tile = gameObjects[0];
        if (
          tile &&
          !this.isGameEnd &&
          tile.name == "tile" &&
          !tile.isBroken &&
          !this.stopPlay &&
          this.isGameStart
        ) {
          this.pickaxe.x = event.x;
          this.pickaxe.y = event.y;
          this.boom.x = event.x + 20;
          this.boom.y = event.y + 10;
          this.boom.play("diging");
          this.digStoneSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.digStoneSound.play();
          this.pickaxe.play("dig");

          this.pickaxe.on("animationcomplete", () => {
            this.pickaxe.x = this.avatarObject[0].x + 60;
            this.pickaxe.y = this.avatarObject[0].y;
            this.pickaxe.setFrame(0);
          });
          if (tile.life == 3) {
            tile.life = 2;
            if (tile.type == 3) {
              tile.setFrame(1);
            }
          } else if (tile.life == 2) {
            tile.life = 1;
            if (tile.type == 3) {
              tile.setFrame(2);
            }
            if (tile.type == 2) {
              tile.setFrame(1);
            }
          } else if (tile.life == 1) {
            tile.isBroken = true;
            tile.removeInteractive();
            if (
              this.tileList.filter((item) => item.isBroken).length ==
              this._tile_dirt_count +
                this._tile_stone_count +
                this._tile_steel_count
            ) {
              this.treasure.removeInteractive();
              this.isgetGem = true;

              if (this.friendAvatarObject && this.friendEntered) {
                if (this.friendMask) {
                  this.friendMask.destroy();
                }
                this.friendMask = this.add
                  .image(380, 720, "happy")
                  .setScale(-0.3, 0.3)
                  .setDepth(5);

                this.tweens.add({
                  targets: this.friendAvatarObject,
                  x: 580,
                  duration: 1000,
                  ease: "Power2",
                  repeat: 0,
                  delay: 1500,
                  onStart: () => {
                    if (this.friendMask) {
                      this.friendMask.destroy();
                    }
                    this.tweens.add({
                      targets: this.friendName,
                      x: 580,
                      duration: 1000,
                      ease: "Power2",
                      repeat: 0,
                    });
                  },
                });
              }

              if (this.isBossRound) {
                this.treasureBlink.destroy();
                this.gemTween.stop();
              }

              this.gamdeTimerSound.stop();
              this.finishedTime =
                1 - this.timedEvent.getProgress().toString().substr(0, 4);

              if (this.isBossRound) {
                this.time.delayedCall(2000, () => {
                  this.scene.start("BossScene", {
                    avatar: this.avatar,
                    round: this.round,
                    score: this.score,
                    questions: this.questions,
                    bossRound: this.bossRound,
                    isGemCracked: this.isGemCracked,
                    finishedTime: this.finishedTime,
                    friend: this.friend,
                    noOfRounds: this.noOfRounds,
                    difficulty: this.difficulty,
                    countCorrect: this.countCorrect,
                  });
                  this.tileList = [];
                  this.metalWall = [];
                  this.tileCount = 0;
                  this.missClicks = 0;
                  this.isGameStart = false;
                  this.isGameEnd = false;
                  this.timerWarned = false;
                  this.stopPlay = false;
                  this.isgetGem = false;
                  this.friendEntered = false;
                  this.timedEvent.destroy();
                  this.timedEvent = null;
                  this.registry.destroy();
                  this.events.off();
                  this.scene.stop("PlayScene");
                });
              } else {
                this.feedback.setText(i18n.t("miniGames.mining.cleared"));
                this.feedback.setTint(0x23de46);
                this.tweens.add({
                  targets: this.modelbg,
                  alpha: 0.5,
                  duration: 200,
                  ease: "Sine.easeInOut",
                });
                this.tweens.add({
                  targets: this.feedback,
                  scaleY: 1,
                  scaleX: 1,
                  duration: 200,
                  ease: "Sine.easeInOut",
                  onStart: () => {
                    this.clearedSound.setVolume(
                      store.state.settings.data.audio.sfx *
                        store.state.settings.data.audio.master
                    );
                    this.clearedSound.play();
                  },
                  onComplete: () => {
                    this.tweens.add({
                      targets: this.feedback,
                      scaleY: 0,
                      scaleX: 0,
                      duration: 200,
                      delay: 2000,
                      ease: "Sine.easeInOut",
                    });
                    this.tweens.add({
                      targets: this.modelbg,
                      alpha: 0,
                      duration: 200,
                      delay: 2000,
                      ease: "Sine.easeInOut",
                    });
                    this.tweens.add({
                      targets: [
                        this.clock,
                        this.timerBar,
                        this.timerBarRe,
                        this.timerBox,
                      ],
                      alpha: 0,
                      duration: 500,
                      delay: 2000,
                      ease: "Sine.easeInOut",
                    });
                    this.treasure.mask.destroy();
                    this.treasure.anim.destroy();
                    this.tweens.add({
                      targets: this.treasure,
                      scaleY: 0.1,
                      scaleX: 0.1,
                      duration: 500,
                      ease: "Sine.easeInOut",
                      delay: 2200,
                      onStart: () => {
                        this.collectGemSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.collectGemSound.play();
                      },
                      onComplete: () => {
                        this.tweens.add({
                          targets: this.treasure,
                          scaleX: -0.1,
                          duration: 300,
                          ease: "Sine.easeInOut",
                          delay: 300,
                          yoyo: true,
                          onComplete: () => {
                            this.tweens.add({
                              targets: this.treasure,
                              scaleY: 0.05,
                              scaleX: 0.05,
                              duration: 500,
                              ease: "Sine.easeInOut",
                            });
                            this.treasure.startFollow({
                              duration: 1000,
                              ease: "Quad.easeInOut",
                              onComplete: () => {
                                this.avatarSpeech(
                                  i18n.t("miniGames.general.score") +
                                    " +" +
                                    this.gemScore,
                                  this.time.delayedCall(1000, () => {
                                    this.nextRound();
                                  })
                                );
                                this.scoreIncreaseSound.setVolume(
                                  store.state.settings.data.audio.sfx *
                                    store.state.settings.data.audio.master
                                );
                                this.scoreIncreaseSound.play();
                                this.score += this.gemScore;
                                this.scoreText.setText(
                                  i18n.t("miniGames.general.score") +
                                    " : " +
                                    this.score
                                );
                              },
                            });
                          },
                        });
                      },
                    });
                  },
                });
              }
              if (this.timedEvent) {
                this.timedEvent.remove(false);
              }
              this.mask;
              if (this.speechText) {
                this.speechText.setText("");
              }

              this.timerBar.destroy();

              this.timerBar = this.add
                .graphics()
                .fillStyle(this.finishedTime < 0.3 ? 0xfa3838 : 0x23de46, 1)
                .fillRect(105, 135, 300 * this.finishedTime, 10)
                .setDepth(9);

              if (this.mask) {
                this.mask.destroy();
              }
              this.mask = this.add
                .image(100, 720, "happy")
                .setScale(0.3)
                .setDepth(5);
            }

            tile.anim.play("wallBreak");
            tile.anim.on("animationcomplete", () => {
              tile.destroy();
            });
          }
        }
        if (
          tile &&
          !this.isGameEnd &&
          tile.name == "freezeClock" &&
          !tile.isBroken &&
          !this.stopPlay &&
          this.isGameStart
        ) {
          this.freezeSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.freezeSound.play();

          // this.gamdeTimerSound.setVolume(
          //   store.state.settings.data.audio.sfx *
          //     store.state.settings.data.audio.master
          // );
          // this.gamdeTimerSound.stop();
          // this.timerWarned = false;

          if (this.gamdeTimerSound.isPlaying) {
            this.gamdeTimerSound.stop();
            this.isGamdeTimerSoundStopped = true;
          }

          this.timedEvent.paused = true;

          this.freezeClock.visible = true;
          this.bubbleBar.visible = true;

          this.avatarSpeech(i18n.t("miniGames.mining.timeFreeze"));

          tile.destroy();

          this.time.delayedCall(3000, () => {
            this.freezeClock.visible = false;
            this.bubbleBar.visible = false;
            this.timedEvent.paused = false;
            if (this.isGamdeTimerSoundStopped) {
              this.gamdeTimerSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.gamdeTimerSound.play();
              this.isGamdeTimerSoundStopped = false;
            }
          });
        }
      },
      this
    );

    this.treasure.on("pointerdown", (event) => {
      if (!this.isGameEnd && !this.stopPlay && this.isGameStart) {
        this.pickaxe.x = event.x;
        this.pickaxe.y = event.y;

        this.digGemSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.digGemSound.play();

        this.boom.x = event.x + 20;
        this.boom.y = event.y + 10;
        this.boom.play("diging");

        this.pickaxe.play("dig");
        this.pickaxe.on("animationcomplete", () => {
          this.pickaxe.x = this.avatarObject[0].x + 60;
          this.pickaxe.y = this.avatarObject[0].y;
          this.pickaxe.setFrame(0);
        });
        this.missClicks += 1;
        if (this.missClicks == 1) {
          this.avatarSpeech(i18n.t("miniGames.mining.careful"));
        }

        // todo
        // test for ios and other platforms
        // navigator.vibrate(300)

        if (this.missClicks == 2) {
          if (this.friendAvatarObject && this.friendEntered) {
            if (this.friendMask) {
              this.friendMask.destroy();
            }
            this.friendMask = this.add
              .image(380, 720, "sad")
              .setScale(-0.3, 0.3)
              .setDepth(5);

            this.tweens.add({
              targets: this.friendAvatarObject,
              x: 580,
              duration: 1000,
              ease: "Power2",
              repeat: 0,
              delay: 1500,
              onStart: () => {
                if (this.friendMask) {
                  this.friendMask.destroy();
                }
                this.tweens.add({
                  targets: this.friendName,
                  x: 580,
                  duration: 1000,
                  ease: "Power2",
                  repeat: 0,
                });
              },
            });
          }
          if (this.speechText) {
            this.speechText.setText("");
          }
          this.gamdeTimerSound.stop();
          if (this.isBossRound) {
            this.treasureBlink.destroy();
            this.gemTween.stop();
          }
          this.treasure.anim.play("gemBreak");
          this.gemSplashSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.gemSplashSound.play();
          if (this.mask) {
            this.mask.destroy();
          }
          this.mask = this.add.image(100, 720, "sad").setScale(0.3).setDepth(5);
          this.mask.name = "sad";

          this.feedback.setText(i18n.t("miniGames.mining.gemLost"));
          this.feedback.setTint(0xfa3838);
          this.tweens.add({
            targets: this.modelbg,
            alpha: 0.5,
            duration: 200,
            ease: "Sine.easeInOut",
          });
          this.tweens.add({
            targets: this.feedback,
            scaleY: 1,
            scaleX: 1,
            duration: 200,
            ease: "Sine.easeInOut",
          });
          this.tweens.add({
            targets: [
              this.clock,
              this.timerBar,
              this.timerBarRe,
              this.timerBox,
            ],
            alpha: 0,
            duration: 500,
            ease: "Sine.easeInOut",
          });
          this.timedEvent.remove(false);
          this.treasure.anim.on("animationcomplete", () => {
            this.stopPlay = true;
            this.gameover(true);

            this.treasure.destroy();
          });
        } else if (this.missClicks == 1) {
          this.isGemCracked = true;
          this.treasure.anim.play("gemCrack");
          if (this.mask) {
            this.mask.destroy();
          }
          this.mask = this.add
            .image(100, 720, "fear")
            .setScale(0.3)
            .setDepth(5);
        }
      }
    });

    // Create a Random Tile Map Array
    function randomMap(cells, dirts, stones, steel, clock) {
      let map = new Array(cells).fill(0);
      for (let i = 0; i < dirts; i++) {
        let position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
        if (map[position] != 0) {
          while (map[position] != 0) {
            position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
          }
        }
        map[position] = 1;
      }
      for (let i = 0; i < stones; i++) {
        let position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
        if (map[position] != 0) {
          while (map[position] != 0) {
            position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
          }
        }
        map[position] = 2;
      }
      for (let i = 0; i < steel; i++) {
        let position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
        if (map[position] != 0) {
          while (map[position] != 0) {
            position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
          }
        }
        map[position] = 3;
      }
      for (let i = 0; i < clock; i++) {
        let position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
        if (map[position] != 0) {
          while (map[position] != 0) {
            position = Math.floor(Math.random() * (cells - 0 + 1) + 0);
          }
        }
        map[position] = -1;
      }
      return map;
    }

    // On timer end
    function onEvent() {
      if (this.speechText) {
        this.speechText.setText("");
      }
      this.treasure.removeInteractive();

      if (this.friendAvatarObject && this.friendEntered) {
        if (this.friendMask) {
          this.friendMask.destroy();
        }
        this.friendMask = this.add
          .image(380, 720, "sad")
          .setScale(-0.3, 0.3)
          .setDepth(5);

        this.tweens.add({
          targets: this.friendAvatarObject,
          x: 580,
          duration: 1000,
          ease: "Power2",
          repeat: 0,
          delay: 1500,
          onStart: () => {
            if (this.friendMask) {
              this.friendMask.destroy();
            }
            this.tweens.add({
              targets: this.friendName,
              x: 580,
              duration: 1000,
              ease: "Power2",
              repeat: 0,
            });
          },
        });
      }

      if (this.isBossRound) {
        this.treasureBlink.destroy();
        this.gemTween.stop();
      }
      this.gamdeTimerSound.stop();
      this.gemLossSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.gemLossSound.play();
      this.stopPlay = true;
      this.time.delayedCall(
        2000,
        () => {
          this.stones = this.add
            .sprite(240, 390, "stones")
            .setScale(1)
            .setFrame(0);
          this.stones.play("fill");
          this.spillStonesSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.spillStonesSound.play();

          this.gameover();
        },
        [],
        this
      );

      this.feedback.setText(i18n.t("miniGames.mining.timeUp"));
      this.feedback.setTint(0xfa3838);
      this.tweens.add({
        targets: this.modelbg,
        alpha: 0.5,
        duration: 200,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: this.feedback,
        scaleY: 1,
        scaleX: 1,
        duration: 200,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: [this.clock, this.timerBar, this.timerBarRe, this.timerBox],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeInOut",
      });
      if (this.mask) {
        this.mask.destroy();
      }
      this.mask = this.add.image(100, 720, "sad").setScale(0.3).setDepth(5);
      this.mask.name = "sad";
    }

    function clickSetting() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "PlayScene",
      });
    }
  }

  gameover(isGemLost = false) {
    if (this.speechText) {
      this.speechText.setText("");
    }
    this.time.delayedCall(2000, () => {
      this.failureSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.gemLostSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      if (isGemLost) {
        this.gemLostSound.play();
      } else {
        this.failureSound.play();
      }

      this.time.delayedCall(1000, () => {
        this.timedEvent.remove(false);
        this.isGameEnd = true;
        this.nextRound();
      });
    });
  }

  nextRound() {
    let tempMiniGame;
    tempMiniGame = _.cloneDeep(store.state.miniGame.data);
    tempMiniGame.score = this.score;
    // tempMiniGame.currnet.round[this.round] = {
    //   isCorrectAnswer: this.isCorrectAnswer,
    //   isPlaySuccess: this.isgetGem,
    // };

    store.commit("updateMiniGame", tempMiniGame);

    if (this.round != this.noOfRounds) {
      if (this.treasure) {
        this.treasure.destroy();
      }
      if (this.staticAxe) {
        this.staticAxe.destroy();
      }
      if (this.pickaxe) {
        this.pickaxe.destroy();
      }
      if (this.mask && this.mask.name == "sad") {
        this.mask.destroy();
      }

      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.31,
        scaleX: 0.29,
        y: 710,
        duration: 700,
        ease: "Sine.easeInOut",
        repeat: 1,
        yoyo: true,
        delay: 2000,
        onStart: () => {
          if (this.mask) {
            this.tweens.add({
              targets: this.mask,
              scaleY: 0.31,
              scaleX: 0.29,
              y: 710,
              duration: 700,
              ease: "Sine.easeInOut",
              repeat: 1,
              yoyo: true,
            });
          }
        },
        onComplete: () => {
          this.scene.start("RoundStartScene", {
            avatar: this.avatar,
            round: this.round + 1,
            score: this.score,
            questions: this.questions,
            friend: this.friend,
            bossRound: this.bossRound,
            noOfRounds: this.noOfRounds,
            difficulty: this.difficulty,
            countCorrect: this.countCorrect,
          });
          this.tileList = [];
          this.metalWall = [];
          this.tileCount = 0;
          this.missClicks = 0;
          this.isGemCracked = false;
          this.isGameStart = false;
          this.isGameEnd = false;
          this.timerWarned = false;
          this.stopPlay = false;
          this.isgetGem = false;
          this.friendEntered = false;
          this.timedEvent.destroy();
          this.timedEvent = null;
          this.registry.destroy();
          this.events.off();
          this.scene.stop("PlayScene");
        },
      });

      this.newBg = this.add.image(760, 427, "bg").setScale(0.5);
      this.newWall = this.add.image(720, 390, "wall").setScale(1.12);
      for (let j = 0; j < 4; j++) {
        for (let i = 0; i < 4; i++) {
          let tile = {};
          tile = this.add.image(570 + i * 100, 240 + j * 100, "steelWall");
          this.newMetalWall.push(tile);
        }
      }

      this.tweens.add({
        targets: [this.wall, this.feedback, this.stones, this.modelbg],
        x: -240,
        duration: 1000,
        ease: "Sine.easeInOut",
        delay: 2000,
        onComplete: () => {
          if (this.mask) {
            this.mask.destroy();
          }
          if (this.speechText) {
            this.speechText.destroy();
          }
        },
      });

      this.tileList.forEach((element) => {
        this.tweens.add({
          targets: [element, element.mask, element.anim],
          x: element.x - 480,
          duration: 1000,
          ease: "Sine.easeInOut",
          delay: 2000,
        });
      });

      this.newMetalWall.forEach((element) => {
        this.tweens.add({
          targets: element,
          x: element.x - 480,
          duration: 1000,
          ease: "Sine.easeInOut",
          delay: 2000,
        });
      });

      this.tweens.add({
        targets: this.newWall,
        x: 240,
        duration: 1000,
        ease: "Sine.easeInOut",
        delay: 2000,
      });
    } else {
      if (store.state.settings.data.cache) {
        let game_cache_data = _.cloneDeep(store.state.miniGame.data);
        game_cache_data.round = this.round + 1;
        game_cache_data.score = this.score;
        game_cache_data.countCorrect = this.countCorrect;

        store.commit("updateMiniGame", game_cache_data);
      }
      // let star;
      // this.event_data = _.cloneDeep(store.state.event.data);
      // if (this.score >= 600 && this.score < 750) {
      //   star = 1;
      // } else if (this.score >= 750 && this.score < 900) {
      //   star = 2;
      // } else if (this.score >= 900) {
      //   star = 3;
      // } else {
      //   star = 0;
      // }

      // this.event_data.submission.countCorrect = this.countCorrect;
      // this.event_data.submission.accuracy = this.score / 10;
      // this.event_data.submission.star = star;
      // this.event_data.submission.score = this.score;
      // this.event_data.submission.submission += 1;

      // this.event_data.record.attempt += 1;
      // this.event_data.record.submission += 1;
      // this.event_data.record.topScoreOld = this.event_data.record.topScore;
      // this.event_data.record.topStarOld = this.event_data.record.topStar;
      // if (this.score > this.event_data.record.topScore) {
      //   this.event_data.record.topScore = this.score;
      //   this.event_data.record.topStar = star;
      // }

      // this.event_data.gameLoot = Math.floor(Math.random() * (5 - 3 + 1) + 3);
      // store.commit("updateEvent", this.event_data);

      let tempMiniGame = _.cloneDeep(store.state.miniGame.data);
      tempMiniGame.isSaved = false;

      store.commit("updateMiniGame", tempMiniGame);

      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageExploreDailyGameScore",
        });
      });
    }
  }

  avatarSpeech(text, callback) {
    if (this.speechText) {
      this.speechText.setText(text);
      this.tweens.add({
        targets: this.speechText,
        alpha: 1,
        yoyo: true,
        duration: 1000,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: this.speechText,
        y: 600,
        duration: 2000,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.speechText.y = 650;
          if (callback) {
            callback;
          }
        },
      });
    }
  }

  update() {
    // Timer progress bar update
    if (this.timedEvent) {
      let x = -300 * this.timedEvent.getProgress().toString().substr(0, 4);
      this.timerBarRe.fillRect(405, 135, x, 10).setDepth(5);
    }

    if (
      this.timedEvent &&
      this.timedEvent.getProgress().toString().substr(0, 4) == 0.7 &&
      !this.timerWarned
    ) {
      this.gamdeTimerSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.gamdeTimerSound.play();
      this.timerWarned = true;
      this.avatarSpeech(i18n.t("miniGames.mining.hurryUp"));
      this.timerBar.destroy();
      this.timerBar = this.add.graphics();
      this.timerBar.fillStyle(0xfa3838, 1);
      this.timerBar.fillRect(105, 135, 300, 10).setDepth(4);

      if (this.mask) {
        this.mask.destroy();
      }
      this.mask = this.add.image(100, 720, "fear").setScale(0.3).setDepth(5);
    }
  }
}
