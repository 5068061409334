import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";
import router from "@/router";
import _ from "lodash";

export default class AnsCorrectPlayScene extends Scene {
  constructor() {
    super({ key: "AnsCorrectPlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  customerCount = 0;
  exitCustomerCount = 0;
  customerList = [];
  gameOngoing = true;
  wave = 0;
  timeUpCount = 0;
  IS_MOBILE =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  create() {
    //* setup the Background  *//
    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.imgDoor = this.add.image(0, 0, "imgDoor").setOrigin(0).setDepth(2);
    this.sptDoor = this.add.sprite(233, 150, "sptDoor").setScale(0.9);
    this.imgCounter = this.add
      .image(0, 550, "imgCounter")
      .setOrigin(0)
      .setDepth(2);
    this.imgWoodPlane1 = this.add.image(97, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane2 = this.add.image(237, 690, "imgWoodPlane").setDepth(2);
    this.imgWoodPlane3 = this.add.image(377, 690, "imgWoodPlane").setDepth(2);
    this.scoreBoard = this.add
      .image(80, 35, "imgScorePanel")
      .setScale(0.3)
      .setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "imgScorePanel")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);

    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    // * setup the Sprite Animations *//

    this.anims.create({
      key: "animMagic",
      frames: this.anims.generateFrameNumbers("sptMagic", {
        frames: [0, 1, 2, 3, 4, 5, 6, 5, 6, 7, 8, 9],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "animDoorOpen",
      frames: this.anims.generateFrameNumbers("sptDoor", {
        frames: [0, 1, 2, 3, 4, 5, 5, 5, 5, 5, 4, 3, 2, 1, 0],
      }),
      frameRate: 12,
    });

    this.anims.create({
      key: "animStoveOn",
      frames: this.anims.generateFrameNumbers("sptStovePan", {
        frames: [1, 2],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooking",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [3, 4, 5, 0],
      }),
      frameRate: 3,
      repeat: -1,
    });

    this.anims.create({
      key: "animCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [1, 2],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animBurning",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [8, 9],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animOverCooked",
      frames: this.anims.generateFrameNumbers("sptStoveActions", {
        frames: [6, 7],
      }),
      frameRate: 2,
      repeat: -1,
    });

    this.anims.create({
      key: "animFireExting",
      frames: this.anims.generateFrameNumbers("sptFireExtingActions", {
        frames: [0, 1, 2, 3, 4, 4, 3, 4, 4, 3, 4, 4, 3, 2, 1, 0],
      }),
      frameRate: 10,
    });

    //* declare the SFXs *//
    this.sfxScoreIncrease = this.sound.add("sfxScoreIncrease");
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxGo = this.sound.add("sfxGo");
    this.sfxDoor = this.sound.add("sfxDoor");
    this.sfxBubble = this.sound.add("sfxBubble");
    this.sfxCookingBell = this.sound.add("sfxCookingBell");
    this.sfxMagic = this.sound.add("sfxMagic");
    this.sfxSlide = this.sound.add("sfxSlide");
    this.sfxShine = this.sound.add("sfxShine");
    this.sfxWrong = this.sound.add("sfxWrong");

    this.sfxA = this.sound.add("sfxA");
    this.sfxB = this.sound.add("sfxB");
    this.sfxC = this.sound.add("sfxC");
    this.sfxD = this.sound.add("sfxD");
    this.sfxE = this.sound.add("sfxE");

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 770, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    //* Stoves and raw foods *//
    this.stoveGroup = this.add.group();

    for (let index = 0; index < 3; index++) {
      const stove = this.add
        .sprite(140 * (index + 1) - 50, 605, "sptStovePan")
        .setFrame(0)
        .setDepth(2);
      stove.status = "OFF";
      stove.actions = this.add
        .sprite(140 * (index + 1) - 44, 580, "sptStoveActions")
        .setFrame(0)
        .setDepth(4);
      stove._id = index + 1;
      this.stoveGroup.add(stove);
    }

    this.imgRawCrab = this.add
      .sprite(97, 690, "sptCrab")
      .setFrame(0)
      .setDepth(2);
    this.imgRawWing = this.add
      .sprite(237, 690, "sptWing")
      .setFrame(0)
      .setDepth(2);
    this.imgRawSteak = this.add
      .sprite(377, 690, "sptSteak")
      .setFrame(0)
      .setDepth(2);

    this.coockedFoodGroup = this.add.group();

    let foodList = [1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2];
    for (let i = foodList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [foodList[i], foodList[j]] = [foodList[j], foodList[i]];
    }

    for (let index = 0; index < 12; index++) {
      const food = this.add
        .image(
          105 * ((index + 1) % 4) + 560,
          575 + Math.floor(index / 4) * 65,
          foodList[index] == 1 ? "imgJelly" : "imgCake"
        )
        .setDepth(2);
      food._x = 105 * ((index + 1) % 4) + 80;
      food._y = 575 + Math.floor(index / 4) * 65;
      food.food = foodList[index] == 1 ? "JELLY" : "CAKE";
      food.score = foodList[index] == 1 ? 8 : 16;
      food.status = "DONE";
      food.actions = this.add
        .sprite(
          105 * ((index + 1) % 4) + 560,
          575 + Math.floor(index / 4) * 65,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(4);
      food.actions.play("animCooked");
      food._id = index + 1;
      food.setInteractive({ draggable: true });
      this.coockedFoodGroup.add(food);
    }

    //* Tools *//
    this.sptFireExting = this.add
      .sprite(420, 795, "sptFireExting")
      .setDepth(4)
      .setFrame(0)
      .setInteractive({ useHandCursor: true, draggable: true });
    this.sptFireExting.name = "Exting";
    this.sptFireExting._x = 420;
    this.sptFireExting._y = 795;

    this.imgBin = this.add.image(60, 810, "imgTrashBin").setDepth(2);

    //* Customers Group *//
    this.customerGroup = this.add.group();

    //* Game transitions starts here *//

    this.sfxShine.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxShine.play();
    this.sfxShine.loop = true;

    this.avatarShine = [];
    this.avatarShine.push(
      this.add
        .sprite(
          this.avatarObject[0].x - 20,
          this.avatarObject[0].y + 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),
      this.add
        .sprite(
          this.avatarObject[0].x - 10,
          this.avatarObject[0].y - 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),

      this.add
        .sprite(
          this.avatarObject[0].x + 20,
          this.avatarObject[0].y + 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked"),

      this.add
        .sprite(
          this.avatarObject[0].x - 10,
          this.avatarObject[0].y - 20,
          "sptStoveActions"
        )
        .setFrame(0)
        .setDepth(6)
        .setScale(1.2)
        .play("animCooked")
    );

    this.imgRushHour = this.add
      .image(480, 250, "imgRushHour")
      .setScale(0.7)
      .setOrigin(0)
      .setDepth(4);

    this.tweens.add({
      targets: [this.imgRushHour],
      x: "-=360",
      duration: 500,
      ease: "bounce.out",
      onStart: () => {
        this.sfxE.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.sfxE.play();
      },
      onComplete: () => {
        this.tweens.add({
          targets: [this.imgRushHour],
          x: "-=440",
          duration: 500,
          delay: 1200,
          ease: "bounce.in",
          onStart: () => {
            this.sfxA.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.sfxA.play();
          },
          onComplete: () => {
            this.tweens.add({
              targets: this.avatarObject,
              y: "+=20",
              ease: "bounce.inout",
              duration: 500,
              yoyo: true,
              onComplete: () => {
                this.sptMagic = this.add
                  .sprite(0, 550, "sptMagic")
                  .setOrigin(0)
                  .setDepth(2);
                this.sptMagic.play("animMagic");
                this.sfxMagic.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxMagic.play();
                this.sptMagic.on(
                  "animationupdate",
                  function (animation, frame) {
                    this.sfxShine.stop();
                    this.avatarShine.forEach((element) => {
                      element.anims.stop("animCooked");
                      element.visible = false;
                    });
                    if (frame.index === 5) {
                      this.startGame();
                    }
                    if (frame.index === 7) {
                      if (this.avatarMask) {
                        this.avatarMask.destroy();
                      }
                      this.avatarMask = this.add
                        .image(
                          this.avatarObject[0].x,
                          this.avatarObject[0].y,
                          "maskHappyx"
                        )
                        .setScale(0.25)
                        .setDepth(6);
                    }
                  },
                  this
                );
              },
            });
          },
        });
      },
    });

    // * Dragging settings *//
    this.input.on("drag", (pointer, gameObject, dragX, dragY) => {
      if (this.gameOngoing) {
        gameObject.x = dragX;
        gameObject.y = dragY;
      }

      if (gameObject.name == "Exting") {
        gameObject.setScale(0.8);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        this.imgBin.setScale(1.1);
      } else {
        this.imgBin.setScale(1);
      }

      this.customerGroup.getChildren().forEach(function (customer) {
        if (
          Phaser.Geom.Intersects.RectangleToRectangle(
            gameObject.getBounds(),
            new Phaser.Geom.Rectangle(
              customer.order.itemBbl.getBounds().x + 10,
              customer.order.itemBbl.getBounds().y + 20,
              customer.order.itemBbl.getBounds().width - 20,
              customer.order.itemBbl.getBounds().height - 40
            )
          ) ||
          Phaser.Geom.Intersects.RectangleToRectangle(
            gameObject.getBounds(),
            new Phaser.Geom.Rectangle(
              customer.getBounds().x + 40,
              customer.getBounds().y + 40,
              10,
              10
            )
          )
        ) {
          if (
            gameObject.status == "DONE" &&
            customer.order.item == gameObject.food
          ) {
            customer.order.itemBbl.setTint(0x7affc1);
          } else {
            customer.order.itemBbl.setTint(0xff7a7a);
          }
        } else {
          customer.order.itemBbl.clearTint();
        }
      }, this);
    });

    this.input.on("dragstart", (pointer, gameObject) => {
      if (this.gameOngoing) {
        if (!(gameObject.name && gameObject.name == "Exting")) {
          gameObject.dragging = true;
          gameObject.actions.anims.stop("animCooked");
          gameObject.actions.setFrame(0);
        }
      }
    });

    this.input.on("dragend", (pointer, gameObject) => {
      this.customerGroup.getChildren().forEach(function (customer) {
        if (
          Phaser.Geom.Intersects.RectangleToRectangle(
            gameObject.getBounds(),
            new Phaser.Geom.Rectangle(
              customer.order.itemBbl.getBounds().x + 10,
              customer.order.itemBbl.getBounds().y + 20,
              customer.order.itemBbl.getBounds().width - 20,
              customer.order.itemBbl.getBounds().height - 40
            )
          ) ||
          Phaser.Geom.Intersects.RectangleToRectangle(
            gameObject.getBounds(),
            new Phaser.Geom.Rectangle(
              customer.getBounds().x + 40,
              customer.getBounds().y + 40,
              10,
              10
            )
          )
        ) {
          if (customer.notServed) {
            if (
              gameObject.status == "DONE" &&
              customer.order.item == gameObject.food &&
              customer.order.waitingTime > 0
            ) {
              customer.notServed = false;
              this.time.removeEvent(gameObject.timeEvent);
              gameObject.actions.destroy();
              gameObject.destroy();
              gameObject.actions.setFrame(0);
              if (customer.faceMask) {
                customer.faceMask.destroy();
              }
              customer.faceMask = this.add
                .image(customer.x, customer.y - 15, "maskHappyx")
                .setScale(0.25)
                .setDepth(4);
              this.time.removeEvent(customer.timeEvent);

              customer.order.itemSpt.destroy();
              customer.order.waitingTimeText.visible = false;

              if (customer.order.feedback) {
                customer.order.feedback.destroy();
              }

              customer.order.feedback = this.add
                .sprite(customer.x + 65, customer.y - 30, "sptHeart")
                .setScale(0)
                .setDepth(4)
                .setFrame(0);
              this.tweens.add({
                targets: customer.order.feedback,
                scaleY: 0.9,
                scaleX: 0.9,
                duration: 200,
                ease: "Sine.easeInOut",
              });

              this.time.delayedCall(500, () => {
                // customer.faceMask.destroy();
                this.timeUpCount++;
                if (this.timeUpCount == 6) {
                  this.timeUpCount = 0;
                  if (this.wave == 1 && this.round == this.bossRound) {
                    this.goBoss();
                  } else {
                    this.customerGroup.getChildren().forEach((customer) => {
                      this.customerExit(customer);
                    });
                  }
                }
              });

              this.score += gameObject.score;
              this.scoreText.setText(
                i18n.t("miniGames.general.score") + " : " + this.score
              );
              let scoreHint = this.add
                .text(0, 0, "+" + gameObject.score, {
                  fontFamily: "Sassoon, sans-serif",
                  fontSize: 22,
                  color: "#492a12",
                })
                .setOrigin(0, 0.5)
                .setAlpha(0);
              scoreHint.setAlpha(1);
              scoreHint.x = customer.x + 80;
              scoreHint.y = customer.y - 50;
              this.tweens.add({
                targets: [scoreHint],
                y: customer.y - 100,
                duration: 500,
                ease: "Sine.easeOut",
                onStart: () => {
                  this.sfxScoreIncrease.setVolume(
                    store.state.settings.data.audio.sfx *
                      store.state.settings.data.audio.master
                  );
                  this.sfxScoreIncrease.play();
                },
                onComplete: () => {
                  scoreHint.setAlpha(0);
                  scoreHint.destroy();
                },
              });
            } else {
              if (customer.faceMask) {
                customer.faceMask.destroy();
              }
              customer.faceMask = this.add
                .image(customer.x, customer.y - 15, "maskWearyx")
                .setScale(0.25)
                .setDepth(6);

              this.sfxWrong.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.sfxWrong.play();

              if (
                customer.order.waitingTimeText &&
                customer.order.waitingTime
              ) {
                if (customer.order.waitingTime > 3) {
                  customer.order.waitingTime -= 3;
                } else if (customer.order.waitingTime > 1) {
                  customer.order.waitingTime = 1;
                }

                customer.order.waitingTimeText.setText(
                  customer.order.waitingTime + " s"
                );
              }
              this.time.delayedCall(800, () => {
                customer.faceMask.destroy();
                customer.order.itemBbl.clearTint();
              });
            }
          }
        }
      }, this);

      if (gameObject.name && gameObject.name == "Exting") {
        this.stoveGroup.getChildren().forEach(function (stove) {
          if (
            Phaser.Geom.Intersects.RectangleToRectangle(
              gameObject.getBounds(),
              stove.actions.getBounds()
            )
          ) {
            if (stove.actions.isBurning) {
              stove.actions.isBurning = false;
              let fireExting = this.add
                .sprite(stove.x, stove.y - 15, "sptFireExtingActions")
                .setFrame(0)
                .setScale(0.8)
                .setDepth(5)
                .setOrigin(0.2, 0.7);
              fireExting.play("animFireExting").on("animationcomplete", () => {
                fireExting.setAlpha(0);
                fireExting.destroy();
                stove.actions.anims.stop("animBurning");
                stove.actions.setFrame(0);
                stove.actions.removeInteractive();
                stove.setFrame(0);
                stove.stop("animStoveOn");
              });
            }
          }
        }, this);
      }

      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          gameObject.getBounds(),
          this.imgBin.getBounds()
        )
      ) {
        gameObject.actions.destroy();
        gameObject.destroy();
        this.imgBin.setScale(1);
      }

      if (gameObject) {
        if (gameObject.name == "Exting") {
          gameObject.setScale(1);
        }
        gameObject.x = gameObject._x;
        gameObject.y = gameObject._y;
        gameObject.dragging = false;
      }

      // if (gameObject && gameObject.actions) {
      //   gameObject.actions.play("animCooked");
      // }
    });
  }

  update() {
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }
    this.customerGroup.getChildren().forEach(function (customer) {
      customer.order.itemBbl.x = customer.x + 40;
      customer.order.itemBbl.y = customer.y - 30;
      customer.order.itemSpt.x = customer.x + 54;
      customer.order.itemSpt.y = customer.y - 35;
      if (customer.order.feedback) {
        customer.order.feedback.x = customer.x + 90;
        customer.order.feedback.y = customer.y - 30;
      }

      customer.order.waitingTimeText.x = customer.x + 80;
      customer.order.waitingTimeText.y = customer.y - 13;

      if (customer.faceMask) {
        customer.faceMask.x = customer.x;
        customer.faceMask.y = customer.y - 5;
      }
    }, this);

    // if (this.customerGroup.getChildren().length == 0 && this.wave == 1) {
    //   this.wave = 2;
    //   for (let index = 0; index < 6; index++) {
    //     this.customerEnter(index + 1);
    //   }
    // }

    if (this.exitCustomerCount >= 6 && this.gameOngoing) {
      this.time.delayedCall(200, () => {
        this.gameOngoing = false;
        this.finishGame();
      });
    }
  }

  startGame() {
    this.customerList = [1, 1, 2, 2, 2, 2];
    for (let i = this.customerList.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.customerList[i], this.customerList[j]] = [
        this.customerList[j],
        this.customerList[i],
      ];
    }

    this.tweens.add({
      targets: [
        this.imgWoodPlane1,
        this.imgWoodPlane2,
        this.imgWoodPlane3,
        this.imgRawCrab,
        this.imgRawSteak,
        this.imgRawWing,
      ],
      x: "-=480",
      duration: 1000,
      ease: "Sine.easeInOut",
    });
    this.tweens.add({
      targets: this.stoveGroup.getChildren(),
      x: "-=480",
      duration: 1000,
      ease: "Sine.easeInOut",
    });

    this.coockedFoodGroup.getChildren().forEach((element) => {
      this.tweens.add({
        targets: element.actions,
        x: "-=480",
        duration: 1000,
        ease: "Sine.easeInOut",
      });
    });

    this.tweens.add({
      targets: this.coockedFoodGroup.getChildren(),
      x: "-=480",
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        this.wave = 1;
        for (let index = 0; index < 6; index++) {
          this.customerEnter(index + 1);
        }
      },
    });
  }

  finishGame() {
    this.time.removeAllEvents();

    this.time.delayedCall(1000, () => {
      this.gameOngoing = false;
      this.tweens.add({
        targets: [
          this.imgWoodPlane1,
          this.imgWoodPlane2,
          this.imgWoodPlane3,
          this.imgRawCrab,
          this.imgRawSteak,
          this.imgRawWing,
        ],
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
      });
      this.tweens.add({
        targets: this.stoveGroup.getChildren(),
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.sfxSlide.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.sfxSlide.play();
        },
        onComplete: () => {
          this.time.delayedCall(1000, () => {
            this.goPlay();
          });
        },
      });

      this.coockedFoodGroup.getChildren().forEach((element) => {
        this.tweens.add({
          targets: element.actions,
          x: "+=480",
          duration: 1000,
          ease: "Sine.easeInOut",
        });
      });

      this.tweens.add({
        targets: this.coockedFoodGroup.getChildren(),
        x: "+=480",
        duration: 1000,
        ease: "Sine.easeInOut",
      });
    });
  }

  customerEnter(token) {
    let customer = this.add
      .image(240, -100, `imgCustomer${Math.floor(Math.random() * 36) + 1}`)
      .setScale(0.85)
      .setDepth(1);

    customer.tokenNumber = token;
    customer.notServed = true;

    let waitingTime;

    switch (this.difficulty) {
      case 3:
        waitingTime = this.IS_MOBILE ? 4 : 6;
        break;
      case 2:
        waitingTime = this.IS_MOBILE ? 7 : 9;
        break;
      default:
        waitingTime = this.IS_MOBILE ? 10 : 12;
        break;
    }

    customer.order = {
      item: this.customerList[this.customerCount] == 1 ? "JELLY" : "CAKE",
      itemBbl: this.add
        .image(240, -100, "imgOrderBubble")
        .setScale(0)
        .setOrigin(0, 0.5),
      itemSpt:
        this.customerList[this.customerCount] == 1
          ? this.add.image(240, -100, "imgJelly").setScale(0).setOrigin(0, 0.5)
          : this.add.image(240, -100, "imgCake").setScale(0).setOrigin(0, 0.5),
      waitingTime: waitingTime,
      waitingTimeText: this.add
        .text(240, -100, waitingTime + " s", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          fill: "#000",
        })
        .setScale(0),
    };
    this.customerGroup.add(customer);
    this.tweens.add({
      targets: customer,
      scaleY: 0.86,
      scaleX: 0.84,
      duration: 500,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: customer,
      y: 150,
      duration: 800,
      ease: "Sine.easeInOut",
      onStart: () => {
        this.doorOpen();
      },
      onComplete: () => {
        if (!customer.moving) this.moveCustomer(customer);
      },
    });

    this.customerCount++;
  }

  moveCustomer(customer) {
    let x = 0;
    let y = 0;
    switch (customer.tokenNumber) {
      case 1:
        x = 100;
        y = 480;
        break;
      case 2:
        x = 320;
        y = 480;
        break;
      case 3:
        x = 100;
        y = 350;
        break;
      case 4:
        x = 320;
        y = 350;
        break;
      case 5:
        x = 100;
        y = 220;
        break;
      case 6:
        x = 320;
        y = 220;
        break;
      default:
        break;
    }

    if (customer.x != x || customer.y != y) {
      customer.moving = true;
      this.tweens.add({
        targets: customer,
        y: y,
        x: x,
        duration: 1000,
        delay: 1000,
        ease: "Sine.easeInOut",
        onComplete: () => {
          customer.moving = false;
          if (!customer.isBubble) {
            customer.isBubble = true;
            this.tweens.add({
              targets: [customer.order.itemBbl, customer.order.waitingTimeText],
              scaleY: 1,
              scaleX: 1,
              duration: 200,
              ease: "Sine.easeInOut",
              onStart: () => {
                this.sfxBubble.setVolume(
                  store.state.settings.data.audio.sfx *
                    store.state.settings.data.audio.master
                );
                this.sfxBubble.play();
              },
              onComplete: () => {
                customer.timeEvent = this.time.addEvent({
                  delay: 1000,
                  callback: () => {
                    customer.order.waitingTime -= 1;
                    customer.order.waitingTimeText.setText(
                      customer.order.waitingTime + " s"
                    );
                    if (!this.gameOngoing) {
                      this.time.removeEvent(customer.timeEvent);
                    }

                    if (customer.order.waitingTime <= 0) {
                      this.time.removeEvent(customer.timeEvent);
                      this.tweens.add({
                        targets: customer.order.itemSpt,
                        scaleY: 0,
                        scaleX: 0,
                        duration: 200,
                        ease: "Sine.easeInOut",
                      });

                      if (customer.faceMask) {
                        customer.faceMask.destroy();
                      }
                      customer.faceMask = this.add
                        .image(customer.x, customer.y - 15, "maskWearyx")
                        .setScale(0.25)
                        .setDepth(4);

                      customer.order.itemSpt.destroy();
                      customer.order.waitingTimeText.visible = false;

                      if (customer.order.feedback) {
                        customer.order.feedback.destroy();
                      }
                      customer.order.feedback = this.add
                        .sprite(customer.x + 90, customer.y - 35, "sptHeart")
                        .setScale(0)
                        .setDepth(4)
                        .setFrame(1);
                      this.tweens.add({
                        targets: customer.order.feedback,
                        scaleY: 0.9,
                        scaleX: 0.9,
                        duration: 200,
                        ease: "Sine.easeInOut",
                      });

                      this.time.delayedCall(500, () => {
                        // customer.faceMask.destroy();
                        this.timeUpCount++;
                        if (this.timeUpCount == 6) {
                          this.timeUpCount = 0;
                          if (this.wave == 1 && this.round == this.bossRound) {
                            this.goBoss();
                          } else {
                            this.customerGroup
                              .getChildren()
                              .forEach((customer) => {
                                this.customerExit(customer);
                              });
                          }
                        }
                      });
                    }
                  },
                  callbackScope: this,
                  loop: true,
                });
              },
            });
            this.tweens.add({
              targets: customer.order.itemSpt,
              scaleY: 0.9,
              scaleX: 0.9,
              duration: 200,
              ease: "Sine.easeInOut",
            });
          }
        },
      });
    }
  }

  refreshToken(removedToken) {
    if (removedToken == 1) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 3);
      if (customer) {
        customer.tokenNumber = 1;
        this.moveCustomer(customer);
        this.refreshToken(3);
      }
    }

    if (removedToken == 2) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 4);
      if (customer) {
        customer.tokenNumber = 2;
        this.moveCustomer(customer);
        this.refreshToken(4);
      }
    }

    if (removedToken == 3) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 5);
      if (customer) {
        customer.tokenNumber = 3;
        this.moveCustomer(customer);
      }
    }

    if (removedToken == 4) {
      let customer = this.customerGroup
        .getChildren()
        .find((customer) => customer.tokenNumber == 6);
      if (customer) {
        customer.tokenNumber = 4;
        this.moveCustomer(customer);
      }
    }
  }

  customerExit(customer) {
    this.exitCustomerCount++;
    const token = customer.tokenNumber;
    customer.faceMask.destroy();

    this.tweens.add({
      targets: [customer, customer.order.feedback, customer.order.itemBbl],
      alpha: 0,
      duration: 200,
      ease: "Sine.easeInOut",
      onComplete: () => {
        customer.order.waitingTimeText.destroy();
        customer.order.feedback.destroy();
        customer.order.itemBbl.destroy();
        customer.destroy();
        this.refreshToken(token);
      },
    });
  }

  doorOpen() {
    this.sptDoor.play("animDoorOpen");
    this.sfxDoor.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxDoor.play();
  }

  doorColose() {
    //
  }

  cooking() {
    //
  }

  finishCooking() {
    //
  }

  clickSetting() {
    if (this.gameOngoing) {
      this.sfxSimpleClick.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxSimpleClick.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "AnsCorrectPlayScene",
      });
    }
  }

  goPlay() {
    if (this.round != this.noOfRounds) {
      let avatarX = this.avatarObject[0] ? this.avatarObject[0].x : 220;
      this.avatarObject = [];
      this.customerCount = 0;
      this.exitCustomerCount = 0;
      this.customerList = [];
      this.gameOngoing = true;
      this.wave = 0;
      this.timeUpCount = 0;
      this.registry.destroy();
      this.events.off();
      this.scene.stop("AnsCorrectPlayScene");

      this.scene.start("PlayScene", {
        avatar: this.avatar,
        round: this.round + 1,
        score: this.score,
        questions: this.questions,
        friend: this.friend,
        bossRound: this.bossRound,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
        countCorrect: this.countCorrect,
        avatarX: avatarX,
      });
    } else {
      let star;
      this.game_cache_data = _.cloneDeep(store.state.miniGame.data);
      this.score += (this.difficulty - 1) * 50;
      if (this.score >= 600 && this.score < 750) {
        star = 1;
      } else if (this.score >= 750 && this.score < 900) {
        star = 2;
      } else if (this.score >= 900) {
        star = 3;
      } else {
        star = 0;
      }

      this.game_cache_data.countCorrect = this.countCorrect;
      this.game_cache_data.star = star;
      this.game_cache_data.score = this.score;

      this.game_cache_data.gameLoot = Math.floor(
        Math.random() * (5 - 3 + 1) + 3
      );
      this.game_cache_data.difficulty = this.difficulty;
      this.game_cache_data.isSaved = false;
      store.commit("updateMiniGame", this.game_cache_data);

      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageExploreDailyGameScore",
        });
      });
    }
  }

  goBoss() {
    let customers = [];
    let foods = [];
    this.customerGroup.getChildren().forEach((element) => {
      customers.push(element);
    });
    this.coockedFoodGroup.getChildren().forEach((element) => {
      foods.push(element);
    });
    let avatarX = this.avatarObject[0] ? this.avatarObject[0].x : 220;
    this.avatarObject = [];
    this.customerCount = 0;
    this.exitCustomerCount = 0;
    this.customerList = [];
    this.gameOngoing = true;
    this.wave = 0;
    this.registry.destroy();
    this.events.off();
    this.scene.stop("AnsCorrectPlayScene");

    this.scene.start("BossScene", {
      avatar: this.avatar,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      bossRound: this.bossRound,
      noOfRounds: this.noOfRounds,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      avatarX: avatarX,
      customers: customers,
      foods: foods,
    });
  }
}
